
















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FormTable from "@/components/vue/FormTable.vue";
import CardModal from "@/components/CardModal.vue";
import { Penalty, PenaltyTemplate, PenaltyTypeEnum } from '../../models/Contracts';
import { ModalResult } from '../../view-models/ModalRequest';
import { SelectOption } from "@/models/enums";

@Component({ components: { FormTable, CardModal } })
export default class AddPenalty extends Vue {
    @Prop() private prop!: {
        penalty: Penalty | null
    };

    public penalty = new PenaltyTemplate(this.prop.penalty);

    get isOk() {
        return !!this.penalty.type 
                && !!this.penalty.amount 
                && !this.penalty.amount.isZero() 
                && !this.penalty.amount.isNaN()
                && !!this.penalty.documentNumber 
                && !!this.penalty.documentDate 
                && !!this.penalty.documentName
                && this.penalty.documentNumber.trim().length !== 0
                && this.penalty.documentName.trim().length !== 0;

    }

    public penaltyTypeOptions: SelectOption<PenaltyTypeEnum | null>[] = [{key: null, desc: ""},{key: PenaltyTypeEnum.FINE, desc: "Штраф"}, {key: PenaltyTypeEnum.PENNY, desc: "Пеня"}];

    public async saveAndClose() {
        if (this.isOk) {
                this.$emit("close-modal", ModalResult.ok({
                    type: this.penalty.type,
                    amount: this.penalty.amount,
                    documentNumber: this.penalty.documentNumber,
                    documentDate: this.penalty.documentDate,
                    documentName: this.penalty.documentName,
                } as Penalty));
        }
    }
}
