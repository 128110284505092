import Contracts from "@/api/Contracts";
import EventBus, {showModal} from "@/EventBus";
import {FormDeclarationBuilder} from "@/form-declarations/contract";
import {Contract, ContractExecutionHistoryEntry, ContractStage} from '@/models/Contracts';
import {ContractStatusStrings, LawType, ObjectStatus} from "@/models/enums";
import FormBase from "@/views/FormBase/FormBase.vue";
import {Component, Prop, Vue} from "vue-property-decorator";
import {ContractScope} from './ContractScope';

import ErrorModal from './modals/ErrorModal.vue';
import MakeChangesModal from './modals/MakeChangesModal.vue';
import router from '@/router';

import ExecutionOperation from './ExecutionOperation.vue';
import {ContractExecutionAction} from '@/models/enums/Contracts/ContractExecutionAction';
import {ContractExecutionOperationState} from '@/models/enums/Contracts';
import {ApiError} from '@/api/ApiError';
import {createRelatedObjects, RelatedObjectsItem} from '@/models/RelatedObjects';
import MockAuthModal from "@/views/CreateContract/modals/MockAuthModal.vue";
import {getLotById} from "@/api/ComposedLots";
import {LotProvision} from "@/models/ComposedLots/LotProvision";
import {ContractPriceEditReason} from "@/models/enums/Contracts/ContractPriceEditReason";
import {format, isSameDay} from "date-fns";
import {EisUploadFilesModal} from "@/modal-views/EisUploadFilesModal";
import {ConfirmationModal} from "@/views/Contracts/modals/ConfirmationModal";
import React from "react";
import {Button} from "@/components/primitive";

function validate(src: ContractScope) {
    const errors = [];

    if (!src.contract.contractNumber || src.contract.contractNumber.trim().length === 0)
        errors.push(`Поле "Номер ${src.contract.law === "F44" ? "контракта" : "договора"}" обязательное, необходимо заполнить`);

    if (src.contract.isAuctionRequired && !src.contract.auctionCost)
        errors.push("Поле \"Цена за право заключения контракта\" обязательное, необходимо заполнить");

    if (src.contract.status !== ObjectStatus.CREATING_APPROVED &&
        src.contract.status !== ObjectStatus.CREATING &&
        src.contract.status !== ObjectStatus.CREATING_FIRST &&
        src.contract.status !== ObjectStatus.SIGNED_BY_SUPPLIER &&
        src.contract.status !== ObjectStatus.ESHOP_CONCLUDED &&
        src.contract.status !== ObjectStatus.CONCLUDED || (
            (src.contract.law === "F223" || (src.contract.law === "F44" && src.contract.isEshop)) &&
            (src.contract.status === ObjectStatus.CREATING
            ||
            src.contract.status === ObjectStatus.CREATING_FIRST)
        )) {
        if (!src.contract.conclusionDate && !src.contract.isEshop)
            errors.push("Поле \"Дата заключения\" обязательное, необходимо заполнить");

        if (!(src.contract.executionDates?.from && src.contract.executionDates?.to))
            errors.push("Поле \"Срок исполнения\" обязательное, необходимо заполнить");

        if (!(src.contract.durationDates?.from && src.contract.durationDates?.to) && !src.contract.isMonopoly)
            errors.push("Поле \"Срок исполнения поставщиком\" обязательное, необходимо заполнить");

        if (!src.contract.subject || src.contract.subject.trim().length === 0)
            errors.push("Поле \"Предмет контракта\" обязательное, необходимо заполнить");

        // This should never happen
        if (!src.contract.supplier)
            errors.push("Поле \"Поставщик\" обязательное, необходимо заполнить");
    }

    const isAggregated = src.contract.isEshop || src.contract.isAggregated;

    if (src.contract.newStages.length > 0 && src.contract.durationDates && src.contract.status != ObjectStatus.CREATING_FIRST && src.contract.status != ObjectStatus.CREATING) {
        if (src.contract.law === LawType.F44) {
            const newStages = src.contract.newStages;
            const rangeStart = newStages[0].startDate;
            const rangeEnd = newStages[newStages.length - 1].endDate;

            const fd = (date: Date) => format(date, "dd.MM.yyyy");
            if (!rangeStart && !src.contract.isEshop) {
                errors.push("Не указана дата начала этапа");
                return errors;
            }

            if (!rangeEnd) {
                errors.push("Не указана дата окончания этапа");
                return errors;
            }

            if (!src.contract.durationDates?.to) {
                errors.push("Не указана дата окончания исполнения контракта");
                return errors;
            }

            if (!src.contract.durationDates?.from) {
                errors.push("Не указана дата начала исполнения контракта");
                return errors;
            }

            if (rangeStart && !isSameDay(src.contract.durationDates?.from, rangeStart)) {
                errors.push(`Дата начала первого этапа контракта ${fd(rangeStart)} должна совпадать с датой начала исполнения контракта ${fd(src.contract.durationDates?.from)}`);
            }

            if (!isSameDay(src.contract.durationDates?.to, rangeEnd)) {
                errors.push(`Дата окончания последнего этапа контракта ${fd(rangeEnd)} должна совпадать с датой окончания исполнения контракта ${fd(src.contract.durationDates?.to)}`);
            }

            if(!src.contract.isEshop) {
                for(let i = 0; i < newStages.length; i++) {
                    const contractDurationStart = src.contract.durationDates?.from;
                    const contractDurationEnd = src.contract.durationDates?.to;
                    const reviewDate = newStages[i].reviewDocumentsDate;
                    const sendDocumentsDate = newStages[i].sendDocumentsDate;
                    const paymentDate = newStages[i].paymentDate;

                    if(reviewDate && contractDurationStart && reviewDate! < contractDurationStart) {
                        errors.push(`Дата рассмотрения отчетных документов ${fd(reviewDate!)} должна быть больше или равна дате начала исполнения контракта ${fd(contractDurationStart)}`);
                    }
                    if(reviewDate && contractDurationEnd && reviewDate! > contractDurationEnd) {
                        errors.push(`Дата рассмотрения отчетных документов ${fd(reviewDate!)} должна быть меньше или равна дате окончания исполнения контракта ${fd(contractDurationEnd)}`);
                    }
                    if(sendDocumentsDate && contractDurationStart && sendDocumentsDate! < contractDurationStart) {
                        errors.push(`Дата отправки отчетных документов ${fd(sendDocumentsDate!)} должна быть больше или равна дате начала исполнения контракта ${fd(contractDurationStart)}`);
                    }
                    if(sendDocumentsDate && contractDurationEnd && sendDocumentsDate! > contractDurationEnd) {
                        errors.push(`Дата отправки отчетных документов ${fd(sendDocumentsDate!)} должна быть меньше или равна дате окончания исполнения контракта ${fd(contractDurationEnd)}`);
                    }
                    if(paymentDate && contractDurationStart && paymentDate! < contractDurationStart) {
                        errors.push(`Дата оплаты ${fd(paymentDate!)} должна быть больше или равна дате начала исполнения контракта ${fd(contractDurationStart)}`);
                    }
                    if(paymentDate && contractDurationEnd && paymentDate! > contractDurationEnd) {
                        errors.push(`Дата оплаты ${fd(paymentDate!)} должна быть меньше или равна дате окончания исполнения контракта ${fd(contractDurationEnd)}`);
                    }
                }
            }

            if (!isAggregated) {
                if (!rangeStart) {
                    errors.push("Не указана дата начала этапа");
                    return errors;
                }

                if (!isSameDay(src.contract.durationDates?.from, rangeStart)) {
                    errors.push(`Дата начала первого этапа контракта ${fd(rangeStart)} должна совпадать с датой начала исполнения контракта ${fd(src.contract.durationDates?.from)}`);
                }
            }
        } else {
            const newStages = src.contract.newStages;
            const rangeStart = newStages[0].startDate;
            const rangeEnd = newStages[newStages.length - 1].endDate;

            const fd = (date: Date) => format(date, "dd.MM.yyyy");

            if (!rangeStart && !src.contract.isEshop) {
                errors.push("Не указана дата начала этапа");
                return errors;
            }

            if (!rangeEnd) {
                errors.push("Не указана дата окончания этапа");
                return errors;
            }

            if (!src.contract.durationDates?.to) {
                errors.push("Не указана дата окончания исполнения договора");
                return errors;
            }

            if (!src.contract.durationDates?.from) {
                errors.push("Не указана дата начала исполнения договора");
                return errors;
            }
            //versionNumber >= 0 - don't check date after contract has been executed
            if (rangeStart && !isSameDay(src.contract.durationDates?.from, rangeStart) && !(src.contract.versionNumber >= 0)) {
                errors.push(`Дата начала первого этапа договора ${fd(rangeStart)} должна совпадать с датой начала исполнения договора ${fd(src.contract.durationDates?.from)}`);
            }

            if (!isSameDay(src.contract.durationDates?.to, rangeEnd)) {
                errors.push(`Дата окончания последнего этапа договора ${fd(rangeEnd)} должна совпадать с датой окончания исполнения договора ${fd(src.contract.durationDates?.to)}`);
            }

            if(!src.contract.isEshop) {
                for(let i = 0; i < newStages.length; i++) {
                    const contractDurationEnd = src.contract.durationDates?.to;
                    const reviewDate = newStages[i].reviewDocumentsDate;
                    const sendDocumentsDate = newStages[i].sendDocumentsDate;
                    const paymentDate = newStages[i].paymentDate;

                    if(reviewDate && contractDurationEnd && reviewDate! > contractDurationEnd) {
                        errors.push(`Дата рассмотрения отчетных документов ${fd(reviewDate!)} должна быть меньше или равна дате окончания исполнения договора ${fd(contractDurationEnd)}`);
                    }
                    if(sendDocumentsDate && contractDurationEnd && sendDocumentsDate! > contractDurationEnd) {
                        errors.push(`Дата отправки отчетных документов ${fd(sendDocumentsDate!)} должна быть меньше или равна дате окончания исполнения договора ${fd(contractDurationEnd)}`);
                    }
                    if(paymentDate && contractDurationEnd && paymentDate! > contractDurationEnd) {
                        errors.push(`Дата оплаты ${fd(paymentDate!)} должна быть меньше или равна дате окончания исполнения договора ${fd(contractDurationEnd)}`);
                    }
                }
            }
            if(src.executionHistory.filter(eh => (eh.state === ContractExecutionOperationState.SIGNED)).length) {
                if (src.stageScopes.filter(ss => ss.stage.endDate == null).length) {
                    errors.push(`Не указана дата окончания этапа`);
                }
                if (src.stageScopes.filter(ss =>  ss.stage.paymentDate == null).length) {
                    errors.push(`Не указана дата оплаты этапа`);
                }
            }
        }
    }


    if (!(src.contract.status === "CREATING" || src.contract.status === "CREATING_FIRST")) {
        if (!src.contract.conclusionDate && !src.contract.isEshop) {
            errors.push("Поле \"Дата заключения\" обязательное, необходимо заполнить");
        }

        if (!src.contract.executionDates?.to || !src.contract.executionDates?.from) {
            errors.push("Поле \"Срок исполнения поставщиком\" обязательное, необходимо заполнить");
        }

        if ((!src.contract.durationDates?.to || !src.contract.durationDates?.from) && !src.contract.isMonopoly) {
            errors.push("Поле \"Срок исполнения\" обязательное, необходимо заполнить");
        }
    }

    if (errors.length === 0) {
        for (const stage of src.contract.newStages) {
            if (!src.contract.isEshop && !(src.contract.status === ObjectStatus.CREATING || src.contract.status === ObjectStatus.CREATING_FIRST)) {
                if (!stage.startDate)
                    errors.push("Необходимо заполнить дату начала этапа");
                if (!stage.endDate)
                    errors.push("Необходимо заполнить дату окончания этапа");
            }
        }
    }

    if (src.contract.isEshop && src.contract.isElectronicExecution === null) {
        errors.push("Поле \"Электронное исполнение\" обязательное, необходимо заполнить.");
    }

    return errors;
}

@Component({ components: { FormBase } })
export default class CreateContract extends Vue {
    @Prop({ default: null }) private objectId!: string;

    public src: ContractScope = {
        contract: null!,
        realContract: null!,
        loading: true,
        stageScopes: [],
        claimWorks: [],
        documents: [],
        economy: [],
        executionProvision: [],
        guaranteeProvision: [],
        financialSources: [],
        subcontractors: [],
        additionalConclusions: [],
        executionHistory: [],
        guarranteeEvents: []
    };

    private async setContract(contract: Contract) {
        this.src = {
            contract: contract,
            realContract: contract.clone(),
            loading: true,
            stageScopes: this.src.stageScopes,
            claimWorks: this.src.claimWorks,
            documents: this.src.documents,
            economy: this.src.economy,
            executionProvision: this.src.executionProvision,
            guaranteeProvision: this.src.guaranteeProvision,
            financialSources: this.src.financialSources,
            subcontractors: this.src.subcontractors,
            additionalConclusions: this.src.additionalConclusions,
            executionHistory: this.src.executionHistory,
            guarranteeEvents: this.src.guarranteeEvents
        };

        this.formDeclaration = FormDeclarationBuilder(
            contract.status !== ObjectStatus.CREATING &&
            contract.status !== ObjectStatus.CREATING_FIRST &&
            contract.status !== ObjectStatus.CREATING_APPROVED &&
            contract.status !== ObjectStatus.SIGNED_BY_SUPPLIER &&
            contract.status !== ObjectStatus.CONCLUDED, this, contract.isEshop, contract.law, await this.getLotGuaranteeProvision());

        const quotationSession: RelatedObjectsItem = {
            type: "inline",
            label: "Котировочная сессия",
            subject: contract.quotationSession?.originBigintId ?? "",
            url: `/shop/sessions/${contract.quotationSession?.id}`,
        };

        this.formDeclaration.relatedObjects = [...createRelatedObjects(contract), ...(contract.quotationSession !== null ? [quotationSession] : []) ];

        this.src.loading = true;

        try {
            this.src = { ...this.src, ...await Contracts.getObjects(this.src) };
            this.src.contract.newStages = this.src.stageScopes.map(s => s.stage);
        } finally {
            this.src.loading = false;
        }
    }

    public async getLotGuaranteeProvision(): Promise<LotProvision | undefined> {
        if(this.src.contract.lot2020Id) {
            const lot = await getLotById(this.src.contract.lot2020Id);
            return lot.guaranteeProvision;
        }
        else if(this.src.contract.lotId){
            const lot = await getLotById(this.src.contract.lotId);
            return  lot.guaranteeProvision;
        }
        return undefined;
    }

    public formDeclaration = FormDeclarationBuilder(false, this);

    public get header() {
        const num = this.src.contract && this.src.contract.contractNumber;
        const status = this.src.contract && this.src.contract.status;
        const law = this.src.contract && this.src.contract.law;
        return law === LawType.F223 ? (status ? `Договор ${num || ""} ${ContractStatusStrings[status]}` : "Договор") : (status ? `Контракт ${num || ""} ${ContractStatusStrings[status]}` : "Контракт");
    }

    /*
    *  #512 - dissolved contract notice
    *  Shows up when contract is dissolved and has some funding left
    *  Example: Для закупки 450037-00800-19Л может быть сформирован контракт на остаток со вторым участником на основании ст. 95 ч. 17.1 44-ФЗ на сумму остатка: 38.24 руб.
    *  При нажатии на номер закупки откроется карточка торгов, в которой возможно сформировать контракт на остаток.
    */
    public get notice() {
        if (this.src.contract.law !== 'F44') {
            return null;
        }

        if (this.src.contract.status !== ObjectStatus.DISSOLVED) return null;
        if (!this.src.realContract) return null;
        // if (!(this.src.stageScopes.length > 1)) return null;

        const total: number = this.src.realContract?.cost.toNumber() ?? 0;
        const paid: number = this.src.stageScopes
            .filter(s => s.stage.status == ObjectStatus.COMPLETED)
            .map(s => s.paymentInfo)
            .flat(1)
            .map(s => s.amount.toNumber())
            .reduce((total, cur, _i) => total + cur, 0);
        const diff: number = total - paid;
        if (diff < 0) return null;
        const output: string = `На основании ч. 17.1 ст. 95 44-ФЗ для лота ${this.src.realContract.lotRegNumber} можно сформировать контракт на оставшийся объем закупки с участником, занявшим следующее место, при этом цена контракта должна быть пропорционально уменьшена.
                                Заключение контракта допускается в случае, если принято решение о включении информации о поставщике, с которым расторгнут контракт, в реестр недобросовестных поставщиков.
                                При нажатии на номер лота откроется карточка лота, в которой можно сформировать контракт на остаток.`;
        return output;
    }

    public get isEnabled() {
        const contract = this.src.contract;

        return !this.src.loading &&
            contract && (
                contract.status === ObjectStatus.CREATING ||
                contract.status === ObjectStatus.CREATING_FIRST ||
                contract.status === ObjectStatus.SIGNED_BY_SUPPLIER ||
                contract.status === ObjectStatus.ESHOP_CONCLUDED ||
                contract.status === ObjectStatus.CREATING_APPROVED);
    }

    public async created() {
        EventBus.$on("add-stage", () => {
            this.src.contract.newStages.push(ContractStage.empty());
            // TODO: stagescope
        });
        const contract = await Contracts.getById(this.objectId);

        this.setContract(contract);
        EventBus.$on("eis-upload", () => {
            showModal(EisUploadFilesModal, { docs: this.src.documents ?? [] });
        });
    }

    public callErrorModal(err: ApiError) {
        EventBus.callModal(ErrorModal, err);
    }

    public async onAction(action: string) {
        function validateAndRun(src: ContractScope, callIfOk: (() => unknown)) {
            const errors = validate(src);

            if (errors?.length)
                EventBus.callModal(ErrorModal, { scope: "Невозможно сохранить", errors });
            else
                callIfOk();
        }

        switch (action) {
            case "save":
                validateAndRun(this.src, () => Contracts.save(this.src.contract).then(this.setContract));
                break;
            case "register":
                //#410 mock auth modal
                if(this.src.contract.law === LawType.F44 || this.src.contract.isMonopoly) {
                    validateAndRun(this.src, () => Contracts.register(this.src.contract).then(this.setContract));
                    break;
                }
                if (await EventBus.callModal(MockAuthModal, {}).then(x => x.isOk))
                    validateAndRun(this.src, () => Contracts.register(this.src.contract).then(this.setContract));
                break;
            case "rollback":
                Contracts
                    .rollback(this.src.contract)
                    .then(contract => {
                        this.setContract(contract);
                        // Vue router does not actually replace view
                        router.replace('/contracts/' + contract.id);
                    });
                break;
            case "make-changes":
                if ((await EventBus.callModal(MakeChangesModal, {
                    completed:
                        this.src.contract.status === ObjectStatus.COMPLETED ||
                        this.src.contract.status === ObjectStatus.DISSOLVED
                })).isOk) {
                    Contracts.makeChanges(this.src.contract).then(this.setContract);
                }
                if (this.src.contract.status === ObjectStatus.COMPLETED) {
                }
                break;
            case "electronic-conclusion":
                await Contracts.save(this.src.contract);
                if (this.src.contract.isEshop) {
                    await Contracts.electronicConclusionValidate(this.src.contract);
                }

                const props = {
                    scope: this.src,
                    source: new ContractExecutionHistoryEntry(
                        null!,
                        null!,
                        ContractExecutionAction.CONTRACT_CONCLUSION,
                        null!,
                        null!,
                        null!,
                        ContractExecutionOperationState.PROJECT,
                        ContractPriceEditReason.NONE,
                        null!,
                        this.src.documents,
                        [])

                };
                EventBus.callModal(ExecutionOperation, props);
                break;
            case "offload-contract":
                const result = await showModal(ConfirmationModal, {text: "Вы уверены, что хотите отправить контракт в АИС «Портал поставщиков»?", title: "Выгрузить контракт в АИС «Портал поставщиков»", width: "40%"});
                if(result) {
                    await showModal(ConfirmationModal, {text: "Контракт успешно отправлен в АИС «Портал поставщиков»", title: <Button disabled style={{cursor: "default", borderRadius: "50%", opacity: 1, border: "none"}} icon="faInfo"/>, width: "40%", notify: true});
                }
                break;
        }
    }
}
